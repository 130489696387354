import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import { paymentService } from "../services/payment.service";
import { set_item_prices, set_subscription } from "../stores/payment";
import { Accordion, Button, CardCbPrice, ClcSpinner } from "@clc-v2/uis";
import classNames from "classnames";
import { IcCheck, IcClc } from "@clc-v2/icons";
import { useNavigate } from "react-router-dom";
import { embedVideo, expired } from "@clc-v2/utilities";
import { NX_DOMAIN } from "../config";
import lodash from "lodash";

const features = [
    "Unlock All Features",
    "Cancel anytime"
]
const benefits = [
    {
        description: "4000+ Daily Poker Quizzes",
        cols: 1
    },
    {
        description: "200+ Hours of Video Training",
        cols: 1
    },
    {
        description: "Exclusive Community Access",
        cols: 1
    },
    {
        description: "Personalized Training Program",
        cols: 1
    },
    {
        description: "Individualized Daily Progress Tracker",
        cols: 1
    },
    {
        description: "Game Theory Optimal Charts",
        cols: 1
    },
    {
        description: "Win a 2-on-1 with Chance and Alex",
        cols: 1
    },
    {
        description: "New Weekly Videos from Chance and Alex",
        cols: 1
    },
]
const faqs = [
    {
        question: "Is this for online or live?",
        answer: "<p>Chip Leader Al was built for both online and live tournaments. The hands that power the Chip Leader Al Engine are generated from real hands Chance Kornuth and Alex Foxen played.</p>",
    },
    {
        question: "How does Chip Leader Al personalize training for each user?",
        answer: "<p>Our AI engine is designed to give you hands on topics you need to work on. As you progress through the platform, the hands will get more advanced.</p>",
    },
    {
        question: "Can I track my progress over time on the platform?",
        answer: "<p>Chip Leader AI will keep track of your daily, weekly, monthly, and even lifetime progress. It is displayed on your personalized dashboard.</p>",
    },
    {
        question: "Was this actually created by Alex and Chance?",
        answer: "<p>All hands and answers in the Chip Leader AI platform were fully written by either Chance or Alex.</p><p>However, we partnered with experts in AI, Design, and Web development to fully create the platform.</p>",
    },
    {
        question: "How did you get the hands?",
        answer: "<p>Chance and Alex selected each hand from their personal databases.</p>",
    },
    {
        question: "What is the cancellation policy?",
        answer: "<p>Free trial for 7 days with no obligation – you will be charged at the end of the free trial. You may cancel your subscription on the account settings page.</p><p>You can also email us at anytime at clai@clcpoker.com to request cancellation.</p><p>Use our free chat service, available 24/7 to request a cancellation. The free chat service is accessible from any page in our software.</p>",
    },
    {
        question: "Will content and hands be added?",
        answer: "<p>Of Course! We will be constantly adding new content and new features to the Chip Leader AI platform.</p><p>5 New Hands are uploaded each week. All new hands are now explained by Chance or Alex in video format to optimize learning.</p><p>Webinars and Online Sessions are added weekly based upon the feedback from the Discord Community</p>",
    },
]

export const Payment = () => {
    const { user } = useAppSelector((state) => state.auth);
    const { item_prices, cb_instance, subscription, until_allowed } = useAppSelector((state) => state.payment);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading_item_prices, set_loading_item_prices] = useState<boolean>(true);
    const [open_manage_subscription, set_open_manage_subscription] = useState<boolean>(false);
    const [payment_proceeding, set_payment_proceeding] = useState<boolean>(false);
    const [opened_benefits, set_opened_benefits] = useState<boolean>(false);
    const manage_subscription = () => {
        if (cb_instance) {
            cb_instance.createChargebeePortal().open({
                // It's not working, seems port is updated recently(2024.09.17)
                loaded: function (param: any) {
                    console.log("CB Instance Loaded ", param)
                },
                close: function (param: any) {
                    console.log("CB Instance Closed ", param)
                    // updateUserProfile();
                },
                visit: function (sectionName: any) {
                    console.log("CB Instance Visited ", sectionName)
                },
                paymentSourceAdd: function (status: any) {
                    console.log("payment source add" + status);
                },
                paymentSourceUpdate: function (status: any) {
                    console.log("payment source update" + status);
                },
                paymentSourceRemove: function (status: any) {
                    console.log("payment source removed");
                },
                subscriptionChanged: function (data: any) {
                    // Optional
                    // called whenever a subscription is changed
                    // data.subscription.id will give you the subscription id
                    // Make sure you whitelist your domain in the checkout settings page
                },
                subscriptionCustomFieldsChanged: function (data: any) {
                    // Optional
                    // called whenever a subscription custom fields are changed
                    // data.subscription.id will give you the subscription id
                    // Make sure you whitelist your domain in the checkout settings page
                },
                subscriptionCancelled: function (data: any) {
                    // Optional
                    // called when a subscription is cancelled
                    // data.subscription.id will give you the subscription id
                    // Make sure you whitelist your domain in the checkout settings page
                    console.log('Sb cancelled ', data)
                },
                subscriptionPaused: function (data: any) {
                    // Optional
                    // called when a subscription is Paused.
                    // data.subscription.id will give you the subscription id
                    // Make sure you whitelist your domain in the checkout settings page
                    console.log('Sb paused ', data)
                },
                subscriptionResumed: function (data: any) {
                    // Optional
                    // called when a paused subscription is resumed.
                    // data.subscription.id will give you the subscription id
                    // Make sure you whitelist your domain in the checkout settings page
                    console.log('Sb resumed ', data)
                },
                scheduledPauseRemoved: function (data: any) {
                    // Optional
                    // called when the schedule to pause a subscription is removed for that subscription.
                    // data.subscription.id will give you the subscription id
                    // Make sure you whitelist your domain in the checkout settings page
                    console.log('Sb pause removed ', data)
                },
                scheduledCancellationRemoved: function (data: any) {
                    // Optional
                    // called when the schedule to cancel a subscription is removed for that subscription.
                    // data.subscription.id will give you the subscription id
                    // Make sure you whitelist your domain in the checkout settings page
                    console.log('Sb cancellation removed ', data)
                },
                subscriptionReactivated: function (data: any) {
                    // Optional
                    // called when a cancelled subscription is reactivated.
                    // data.subscription.id will give you the subscription id
                    // Make sure you whitelist your domain in the checkout settings page
                    console.log('Sb reactivated ', data)
                }
            });
        }
    }
    const subscribe_handler = (item_price_id: string, changing_item_price: boolean) => {
        if (cb_instance && user) {
            if (changing_item_price) {
                set_open_manage_subscription(true);
            } else {
                cb_instance.openCheckout({
                    hostedPage: async function () {
                        return paymentService.generateCheckoutUrl(item_price_id, user.id, user.cb_coupon_id);
                        // return await generateCheckoutNewUrl({
                        //     priceId,
                        //     userStringId: user.stringID!,
                        // });
                    },
                    success: function (hostedPageId: any, param: any) {
                        set_payment_proceeding(true);
                        console.log(hostedPageId, param)
                        // checkoutSuccessHandler(hostedPageId).then((res) => {
                        //     updateUserProfile();
                        // })
                    },
                    close: () => {
                        // setLoadingCb(false);
                    },
                    step(step: any) {
                        console.log("checkout", step);
                    }
                });
            }
        } else {
            console.log("CB Instance is invalid");
        }
    }
    const go_to_dashboard = () => {
        navigate(`/dashboard`);
    }
    const go_to_intro = () => {
        navigate(`/intro`);
    }
    useEffect(() => {
        paymentService.getPriceItems().then((_price_items) => {
            dispatch(
                set_item_prices(_price_items)
            );
        }).catch((err_msg: string) => {
            console.error(err_msg);
        }).finally(() => {
            set_loading_item_prices(false);
        });
    }, []);
    useEffect(() => {
        if (until_allowed !== undefined && !expired(until_allowed, 0)) {
            set_payment_proceeding(false);
            setTimeout(() => {
                if (user?.intro_seen_at) {
                    go_to_dashboard();
                } else {
                    go_to_intro();
                }
            }, 2000);
        }
    }, [until_allowed]);
    useEffect(() => {
        let timer: NodeJS.Timer | null = null;
        const get_current_subscription = () => {
            paymentService.getCurrentSubscription().then((_subscription) => {
                dispatch(
                    set_subscription(_subscription)
                )
            }).catch((err_msg: string) => {
                console.error(err_msg);
            }).finally(() => {
                timer = setTimeout(get_current_subscription, 5000);
            });
        }
        get_current_subscription();
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        }
    }, []);
    if (loading_item_prices) {
        return (
            <div>Loading</div>
        )
    } else {
        return (
            <div className="w-full bg-black-dark font-inter">
                {payment_proceeding && <div className="z-50 fixed left-0 top-0 w-full h-full flex justify-center items-center">
                    <div className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-50"></div>
                    <ClcSpinner text="Loading" />
                </div>}
                <div className="container mx-auto">
                    <div className="w-full flex justify-center">
                        <span className="uppercase text-primary-golden">Pricing</span>
                    </div>
                    <h2 className="mx-auto text-white text-2xl md:text-4xl lg:text-6xl text-center font-bold font-inter mb-6">Train with Chip Leader AI free for 7 days</h2>
                    <div className="w-full flex justify-center items-center gap-6">
                        {features.map((feature, id) => (
                            <div key={id} className="flex items-center gap-2">
                                <div className="w-6 h-6 grow-0 shrink-0">
                                    <IcCheck />
                                </div>
                                <span className="text-white font-inter text-sm shadow-sm">{feature}</span>
                            </div>
                        ))}
                    </div>
                    <div className="w-full md:w-[480px] h-[320px] mx-auto">
                        <iframe
                            src={embedVideo('https://vimeo.com/929979067?share=copy')}
                            width="100%"
                            height="100%"
                            style={{ backgroundColor: '#000' }}
                            allowFullScreen
                        />
                    </div>
                    <div className="w-full flex justify-center gap-4 text-primary-golden text-sm font-medium font-inter mt-8 pb-6">
                        <p className="underline cursor-pointer" onClick={() => set_opened_benefits((_prev) => !_prev)}>Show all Chip Leader AI features</p>
                        <span>{opened_benefits ? '-' : '+'}</span>
                    </div>
                    <div className={classNames(
                        "max-w-4xl mx-auto grid grid-cols-2 px-4 pb-6 gap-y-6",
                        {
                            'hidden': !opened_benefits
                        }
                    )}>
                        {benefits.map((benefit, id) => (
                            <div key={id} className={classNames(
                                {
                                    "col-span-2 md:col-span-1": benefit.cols === 1,
                                    "col-span-2 text-left md:text-center": benefit.cols === 2
                                },
                                "flex items-center gap-4",
                                {
                                    "justify-start": benefit.cols === 1,
                                    "justify-start md:justify-center": benefit.cols === 2
                                },
                                "text-white text-2xl"
                            )}>
                                <div className="w-6 h-6 grow-0 shrink-0">
                                    {/* <IcCheck /> */}
                                    <IcClc />
                                </div>
                                <p className="text-base">{benefit.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="container mx-auto grid grid-cols-3 gap-6">
                    {/* <div className="col-span-3 flex flex-col items-center gap-4 text-white my-6">
                        <h2 className="text-6xl font-black">Pricing</h2>
                        <p className="text-2xl font-bold">First 7 days free - cancel anytime. Your card will not be charged until your free trial ends.</p>
                    </div> */}
                    <div className="col-span-3 rounded-2xl flex justify-center items-center px-6 py-4 bg-[#202225]">
                        <div className="flex flex-col md:flex-row items-center gap-2 font-inter">
                            <span className="text-primary-golden font-bold">Zero Risk Trial:</span>
                            <p className="text-white">Your card will not be charged until the 7-day free trial is over - cancel at anytime!</p>
                        </div>
                    </div>
                    {subscription && until_allowed !== undefined && expired(until_allowed, 12 * 60) && (
                        <div className="col-span-3 font-inter text-red-500 flex flex-col items-center">
                            <p>Your subscription has expired. Please renew your subscription, choosing from the following plans.</p>
                            <p>Your card may be charged immediately if your free trial has been used previously.</p>
                            <p>If you have any questions, please contact us via live chat for assistance.</p>
                        </div>
                    )}
                    {item_prices && (item_prices.length > 0 ? item_prices.map((item_price) => (
                        <CardCbPrice
                            className="col-span-3 lg:col-span-1"
                            key={item_price.id}
                            item_price_id={item_price.id}
                            price={item_price.price}
                            currency_code={item_price.currency_code}
                            name={item_price.external_name}
                            description={item_price.description}
                            selected_or_upgrade={subscription && (subscription.subscription_items.filter(({ item_price_id }) => item_price_id === item_price.id).length > 0)}
                            scheduled={subscription?.scheduled_item_price_id === item_price.id}
                            status={subscription?.status}
                            trial_end={subscription?.trial_end}
                            next_billing_at={subscription?.next_billing_at}
                            canceled_at={subscription?.canceled_at}
                            cancel_schedule_created_at={subscription?.cancel_schedule_created_at}
                            self_description={item_price.self_description}
                            monthly_price={item_price.monthly_price}
                            monthly_price_full={item_price.monthly_price_full}
                            saved_money_text={item_price.saved_money_text}
                            bill_text={item_price.bill_text}
                            tip={item_price.tip}
                            force_new_purchase={subscription && lodash.intersection(item_prices.map((item_price) => item_price.id), subscription.subscription_items.map(({ item_price_id }) => item_price_id)).length === 0}
                            on_select={subscribe_handler}
                            on_manage={manage_subscription}
                            on_reactivate={subscription ? () => paymentService.reactivateSubscription(subscription?.customer_id, subscription?.id) : undefined}
                        />
                    )) : (
                        <div className="col-span-3 flex justify-center">CLC Plans are not registered to database</div>
                    ))}
                    {open_manage_subscription && (
                        <div className="col-span-3 w-full flex justify-center">
                            <Button type="primary" onClick={manage_subscription}>
                                Manage Subscription
                            </Button>
                        </div>
                    )}
                </div>
                <div className="container mx-auto rounded-2xl bg-[#202225] flex flex-col items-center gap-4 py-6 my-6">
                    <p className="text-white">“Chip Leader Coaching under the leadership of Chance is one of the premier poker coaching sites available to us all.”</p>
                    <div className="flex items-center gap-6">
                        <img className="w-16 h-16 border-primary-golden border-2 rounded-full" src={`${NX_DOMAIN}/public/terminologies/terminology-1.png`} />
                        <div className="flex flex-col items-start gap-1">
                            <p className="text-white">Joe Hachem</p>
                            <p className="text-primary-golden">2005 WSOP Main Event Champion</p>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto flex flex-col md:px-10 mt-20">
                    <h2 className="w-full text-center text-white text-4xl font-bold font-inter mb-10 md:mb-20">FAQs about Our Plans & Free Trial</h2>
                    <Accordion data={faqs} />
                </div>
            </div>
        )
    }
}